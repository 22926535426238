import log from "loglevel";
import { useState } from "react";
import { ALL_SIFTS } from "../libs/constants";
import { canMultiInstanceAdmin, getIam } from "../libs/utils";
import AdminInterface from "./AdminInterface";
import GetProduct from "./GetProduct";
import Hardenize from "./Hardenize";
import InstanceSelector from "./InstanceSelector";
import Sift from "./Sift";
import ToolsSelector from "./ToolsSelector";
import ClosedBetaSelector from "./ClosedBetaSelector";
import WaitingRoom from "./WaitingRoom";

const getOrgSiftDetails = async (
  siftId: string,
  guid: string,
  organizationId: string
) => {
  const resp = await getIam(
    `/api/v2/organizations/${organizationId}/sifts/${siftId}/${guid}`
  );
  return resp;
};

const SiftSelector = ({
  isLoading,
  isError,
  selected,
  setSelected,
  user,
  jwt,
  sifts,
  installedIdentities,
  siftsJwt,
  isAdmin = false,
  isCustSupport = false,
  pathname = "",
}: {
  isLoading: boolean;
  isError: boolean;
  selected: any;
  setSelected: Function;
  user: any;
  jwt: string;
  sifts: any;
  installedIdentities: any;
  siftsJwt: any[];
  isAdmin: boolean;
  isCustSupport: boolean;
  pathname: string;
}) => {
  // NOTE: a future optimisation of this UX could be to store the instance selection for a given
  // sift to allow more efficient transition between sift types and then have a way for the user
  // to reset the instance and go back to the instance selector
  const setInstance = async (
    instance: string | { id: string; guid: string; organizationId: string }
  ) => {
    if (typeof instance !== "string") {
      const resp = await getOrgSiftDetails(
        instance.id,
        instance.guid,
        instance.organizationId
      );
      setSelected({
        ...selected,
        instance: instance.id,
        organizationId: instance.organizationId,
        token: resp.token,
        sift: resp.sift,
      });
    } else {
      setSelected({ ...selected, instance });
    }
  };

  if (isLoading || (!isLoading && isError) || !selected.guid) {
    return null;
  } else if (selected.guid && Object.keys(sifts).includes(selected.guid)) {
    // BEGIN: temporary hack to exclude "decommissioned" accounts, this is for the case where user inadvertenly ended up with more than one account of OnINBOX
    if (
      sifts[selected.guid].filter((s: any) => !s.isDecommissioned).length === 0
    ) {
      return <GetProduct product={selected.guid} />;
    } else if (
      sifts[selected.guid].filter((s: any) => !s.isDecommissioned).length ===
        1 &&
      !selected.instance &&
      !canMultiInstanceAdmin(user, selected.guid)
    ) {
      setInstance(
        sifts[selected.guid].filter((s: any) => !s.isDecommissioned)[0].id
      );
      return null;
    }
    // END: temporary hack to exclude "decommissioned" accounts, this is for the case where user inadvertenly ended up with more than one account of OnINBOX
    else if (
      sifts[selected.guid].length === 1 &&
      !selected.instance &&
      !canMultiInstanceAdmin(user, selected.guid)
    ) {
      setInstance(sifts[selected.guid][0].id);
      return null;
    } else if (sifts[selected.guid].length > 0 && selected.instance) {
      log.debug("SiftSelector::selected:", selected);
      const installedSift = sifts[selected.guid].find(
        (s: any) => s.id === selected.instance
      );
      if (!installedSift) {
        // If it is not in the list of direct instances, we have the information from the organization selection
        const { token, id, guid } = selected;
        const siftJwt = { id, guid, token };
        return <Sift user={user} installed={installedSift} siftJwt={siftJwt} />;
      }
      log.debug("SiftSelector::installed", installedSift);
      const siftJwt = siftsJwt.find(
        (s) => s.id === selected.instance && s.guid === selected.guid
      );

      log.debug("SiftSelector::installed", installedSift);
      // Load selected instance
      if (installedSift) {
        const { identity } = installedSift;
        const installed = {
          ...installedSift,
          sift: installedIdentities[identity],
        };
        return <Sift user={user} installed={installed} siftJwt={siftJwt} />;
      }
    }
    // Show instance selector
    return (
      <InstanceSelector
        user={user}
        guid={selected.guid}
        uiconfig={ALL_SIFTS[selected.guid]}
        instances={sifts[selected.guid]}
        setInstance={setInstance}
        pathname={pathname}
      />
    );
  } else if (selected.guid === "tools") {
    return <ToolsSelector user={user} setSelected={setSelected} />;
  } else if (selected.guid === "closed-beta") {
    return <ClosedBetaSelector user={user} setSelected={setSelected} />;
  } else if (selected.guid === "admin" && (isAdmin || isCustSupport)) {
    return <AdminInterface user={user} />;
  } else if (selected.guid === "hardenize") {
    return <Hardenize />;
  } else if (selected.guid === "asm") {
    return <Hardenize product="asm" />;
  } else if (selected.guid === "certificates") {
    return <Hardenize product="certificates" />;
  } else if (
    user?.pendingSifts?.length > 0 &&
    user?.pendingSifts?.some((s: any) => s.guid === selected.guid)
  ) {
    log.debug("SiftSelector::pending", selected.guid);
    return <WaitingRoom product={selected.guid}></WaitingRoom>;
  } else {
    log.debug("SiftSelector::", selected, sifts);
    return <GetProduct product={selected.guid} />;
  }
};

export default SiftSelector;
